import React, { PureComponent } from "react";
import axios from "axios";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { server, config, checkAccess } from "../env";

export default class Settings extends PureComponent {
  state = {
    loader: "",
    message: "",
  };

  componentDidMount = () => {
    document.title = "Account Settings";
  };

  updatePassword = async (e) => {
    e.preventDefault();
    this.setState({
      loader: <Loader />,
    });

    if (e.target.new_password.value !== e.target.cnf_password.value) {
      this.setState({
        message: (
          <Alert className="danger" message="passwords didn't matched" />
        ),
        loader: "",
      });
      return;
    }

    const params = {
      old_password: e.target.password.value,
      new_password: e.target.new_password.value,
    };

    await axios
      .post(server + "/v1/auth/update-password", params, config)
      .then(async (rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="success" message={rsp.data.detail} />,
        });
      })
      .catch((err) => {
        console.log(err);
        checkAccess(err);
        if (err.response) {
          this.setState({
            message: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
        this.setState({
          loader: "",
        });
      });
  };

  render() {
    const { message, loader } = this.state;
    return (
      <div className="main-content">
        <Header pretitle="Account" title="Security Settings" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Change Password</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={this.updatePassword}>
                    {message}
                    <div className="form-group">
                      <label>Old Password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        autoFocus
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        name="newPassword"
                        id="new_password"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="cnf_password"
                        id="cnfPassword"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="form-group float-right mb-n1">
                      <button type="submit" className="btn btn-primary w-100">
                        Change Password {loader}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
