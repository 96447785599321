import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";
import Modal from "../components/modal";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { server, config, checkAccess } from "../env";

export default class Plans extends PureComponent {
  state = {
    plans: [],
    backupPlans: [],

    // current
    plan: "",

    // create plan
    createLoader: "",
    createMessage: "",

    // update plan
    updateLoader: "",
    updateMessage: "",

    // delete plan
    deleteMessage: "",
    deleteLoader: "",
  };

  componentDidMount = () => {
    document.title = "Plans - Hostarena.org";
    this.readPlans();
  };

  // read plan from server
  readPlans = async () => {
    await axios.get(server + "/v1/plan/read").then((rsp) => {
      this.setState({
        plans: rsp.data.payload,
        backupPlans: rsp.data.payload,
      });
    });
  };

  // search at local
  search = (e) => {
    this.setState({
      plans: this.state.backupPlans.filter((plan) =>
        plan.name.toLowerCase().match(e.target.value.toLowerCase())
      ),
    });
  };

  // create plan
  createPlan = async (e) => {
    e.preventDefault();
    this.setState({
      createLoader: <Loader />,
    });

    const params = Array.from(e.target.elements)
      .filter((el) => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    await axios
      .post(server + "/v1/plan/create", params, config)
      .then(async (rsp) => {
        await this.readPlans();
        this.setState({
          createLoader: "",
          createMessage: (
            <Alert className="success" message={rsp.data.detail} />
          ),
        });
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            createMessage: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
        this.setState({
          createLoader: "",
        });
      });
  };

  // onChangeUpdate Form
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // update plan
  updatePlan = async (e) => {
    e.preventDefault();
    this.setState({
      updateLoader: <Loader />,
    });

    const params = Array.from(e.target.elements)
      .filter((el) => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    await axios
      .put(server + "/v1/plan/update/" + this.state.plan, params, config)
      .then(async (rsp) => {
        await this.readPlans();
        this.setState({
          updateLoader: "",
          updateMessage: (
            <Alert className="success" message={rsp.data.detail} />
          ),
        });
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            updateMessage: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
        this.setState({
          updateLoader: "",
        });
      });
  };

  // Delete Plan
  deletePlan = async () => {
    await axios
      .delete(server + "/v1/plan/delete/" + this.state.plan, config)
      .then(async (rsp) => {
        this.setState({
          deleteLoader: "",
          deleteMessage: (
            <Alert className="success" message={rsp.data.detail} />
          ),
          plans: this.state.backupPlans.filter(
            (plan) => plan.id !== this.state.plan
          ),
        });
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            deleteMessage: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
        this.setState({
          deleteLoader: "",
        });
      });
  };

  render() {
    const { plans } = this.state;
    const { createMessage, createLoader } = this.state;
    const { updateMessage, updateLoader } = this.state;
    const { name, price, description } = this.state;
    const { deleteMessage, deleteLoader } = this.state;
    return (
      <div className="main-content">
        <Header pretitle="Dashboard" title="Plans" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <form>
                    <div className="input-group input-group-flush input-group-merge input-group-reverse">
                      <input
                        className="form-control list-search"
                        type="search"
                        placeholder="Search"
                        onChange={this.search}
                      />
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                  </form>

                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-primary"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#create"
                      onClick={() => this.setState({ createMessage: "" })}
                    >
                      Add New Plan
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th>
                          <Link to="#" className="text-muted">
                            Plan ID
                          </Link>
                        </th>
                        <th>
                          <Link to="#" className="text-muted">
                            Plan Name
                          </Link>
                        </th>
                        <th>
                          <Link to="#" className="text-muted">
                            Plan Price
                          </Link>
                        </th>
                        <th>
                          <Link to="#" className="text-muted">
                            Plan Description
                          </Link>
                        </th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {plans.map((plan, index) => (
                        <tr key={index}>
                          <td>{plan.id}</td>
                          <td>{plan.name}</td>
                          <td>${plan.price}</td>
                          <td>{plan.description}</td>
                          <td>
                            <Link
                              to="#"
                              className="btn btn-sm btn-rounded-circle btn-primary mx-2 pt-1"
                              onClick={() =>
                                this.setState({
                                  plan: plan.id,
                                  updateMessage: "",
                                  name: plan.name,
                                  price: plan.price,
                                  description: plan.description,
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#update"
                            >
                              <i className="fe fe-edit-3"></i>
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-sm btn-rounded-circle btn-danger mx-2 pt-1"
                              onClick={() =>
                                this.setState({
                                  plan: plan.id,
                                  deleteMessage: "",
                                })
                              }
                              data-bs-toggle="modal"
                              data-bs-target="#delete"
                            >
                              <i className="fe fe-trash"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}

        {/* Create Plan */}
        <Modal id="create" title="Create Plan">
          <div className="modal-body">
            <form onSubmit={this.createPlan}>
              {createMessage}
              <div className="form-group">
                <label>Plan Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Plan Price</label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  step="0.01"
                  name="price"
                  id="price"
                  required
                />
              </div>
              <div className="form-group">
                <label>Plan Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  className="form-control"
                  required
                ></textarea>
              </div>
              <div
                className="form-group
              d-flex justify-content-end"
              >
                <button type="submit" className="btn btn-primary">
                  Add Plan{createLoader}
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* update plan */}
        <Modal id="update" title="Update Plan">
          <div className="modal-body">
            <form onSubmit={this.updatePlan}>
              {updateMessage}
              <div className="form-group">
                <label>Plan Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  value={name}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Plan Price</label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  step="0.01"
                  name="price"
                  id="price"
                  value={price}
                  onChange={this.onChange}
                  required
                />
              </div>
              <div className="form-group">
                <label>Plan Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={description}
                  onChange={this.onChange}
                  required
                ></textarea>
              </div>
              <div
                className="form-group
              d-flex justify-content-end"
              >
                <button type="submit" className="btn btn-primary">
                  Update Plan{updateLoader}
                </button>
              </div>
            </form>
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal id="delete" title="Delete Plan" style={{ zIndex: 1200 }}>
          <div className="modal-body  text-center">
            {deleteMessage}
            <h3>Are you sure you want to delete?</h3>
            <div className="form-group mt-4">
              <button className="btn btn-primary m-2" data-bs-dismiss="modal">
                Go back
              </button>
              <button
                className="btn btn-danger"
                onClick={this.deletePlan}
                // data-bs-dismiss="modal"
              >
                Continue {deleteLoader}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
