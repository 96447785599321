import React, { PureComponent } from "react";
import Header from "../components/Header";
import axios from "axios";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { server, config, checkAccess } from "../env";

export default class PaymentGateway extends PureComponent {
  state = {
    gateways: [],
    creds: [],
    message: "",
    paddle: false,
    bitpay: false,
    id: "",

    // creds
    updateLoader: "",
  };

  componentDidMount = async () => {
    this.readGateways();
  };

  readGateways = async () => {
    await axios
      .get(server + "/v1/payment_method/read", config)
      .then((rsp) => {
        this.setState({
          gateways: rsp.data.payload.payment_methods,
          creds: rsp.data.payload.payment_creds,
        });
      })
      .catch((err) => {
        checkAccess(err);
      });
  };

  updateGateway = async (status, id) => {
    await this.setState({
      message: "",
    });
    const params = {
      status,
    };
    await axios
      .put(server + "/v1/payment_method/update/" + id, params, config)
      .then(async (rsp) => {
        await this.readGateways();
        this.setState({
          message: <Alert className="success" message={rsp.data.detail} />,
        });
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            message: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
      });
  };

  // update plan
  updateCreds = async (e) => {
    e.preventDefault();
    this.setState({
      updateLoader: <Loader />,
    });

    const params = Array.from(e.target.elements)
      .filter((el) => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: b.value }), {});

    await axios
      .put(server + "/v1/payment_method/creds/update", params, config)
      .then(async (rsp) => {
        this.setState({
          updateLoader: "",
          message: <Alert className="success" message={rsp.data.detail} />,
        });
      })
      .catch((err) => {
        checkAccess(err);
        if (err.response) {
          this.setState({
            message: (
              <Alert className="danger" message={err.response.data.detail} />
            ),
          });
        }
        this.setState({
          updateLoader: "",
        });
      });
  };

  render() {
    const { gateways, creds, message, updateLoader } = this.state;
    return (
      <div className="main-content">
        <Header pretitle="Dashboard" title="Payment Methods" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">{message}</div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Payment Gateways</h4>
                </div>
                <div className="card-body">
                  {gateways.map((gateway, index) => (
                    <div className="form-group">
                      <label htmlFor="">
                        {gateway.name} ({gateway.code})
                      </label>
                      <div className="form-check form-switch my-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="status"
                          name="status"
                          defaultChecked={gateway.status}
                          onChange={(e) =>
                            this.updateGateway(
                              gateway.status ? false : true,
                              gateway.id
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Payment Credentials</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={this.updateCreds}>
                    {creds.map((data, index) => (
                      <div className="form-group" key={index}>
                        <label htmlFor="">{data.key}</label>
                        <input
                          type="text"
                          name={data.key}
                          id={data.key}
                          className="form-control"
                          defaultValue={data.value}
                        />
                      </div>
                    ))}

                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Update Credentials {updateLoader}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
