import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default class Sidebar extends PureComponent {
  state = {
    tab: window.location.href.split("/")[3] || "/",
  };

  logout = () => {
    Cookies.remove("footprint");
    window.location.href = "/login";
  };

  render() {
    const { tab } = this.state;

    return (
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link className="navbar-brand" to="/">
            {/* <img
              src={
                localStorage.getItem("themeMode") === "light"
                  ? "/assets/img/logo 2.png"
                  : "/assets/img/logo 1.png"
              }
              className="navbar-brand-img mx-auto"
              alt="..."
            /> */}
          </Link>

          <div className="navbar-user d-md-none">
            <div className="dropdown">
              <Link
                to="#"
                id="sidebarIcon"
                className="dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar avatar-sm avatar-online">
                  <img
                    src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                    className="avatar-img rounded-circle"
                    alt="..."
                  />
                </div>
              </Link>

              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="sidebarIcon"
              >
                <Link to="/settings" className="dropdown-item">
                  Settings
                </Link>
                <hr className="dropdown-divider" />
                <Link to="#" onClick={this.logout} className="dropdown-item">
                  Logout
                </Link>
              </div>
            </div>
          </div>

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className={tab === "/" ? "nav-link active" : "nav-link"}
                  onClick={() => this.setState({ tab: "/" })}
                  to="/"
                >
                  <i className="fe fe-dollar-sign"></i> Plans
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    tab === "payment-methods" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => this.setState({ tab: "payment-methods" })}
                  to="/payment-methods"
                >
                  <i className="fe fe-box"></i> Payment Gateways
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#" onClick={this.logout}>
                  <i className="fe fe-log-out"></i> Logout
                </Link>
              </li>
            </ul>

            <div className="mt-auto"></div>

            <div className="navbar-user d-none d-md-flex" id="sidebarUser">
              <div className="dropup">
                <Link
                  to="#"
                  id="sidebarIconCopy"
                  className="dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-sm avatar-online">
                    <img
                      src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                      className="avatar-img rounded-circle"
                      alt="..."
                    />
                  </div>
                </Link>

                <div
                  className="dropdown-menu"
                  aria-labelledby="sidebarIconCopy"
                >
                  <Link to="/settings" className="dropdown-item">
                    Settings
                  </Link>
                  <hr className="dropdown-divider" />
                  <Link to="#" onClick={this.logout} className="dropdown-item">
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
