import { BrowserRouter, Switch, Route } from "react-router-dom";

// Helper Components
import Sidebar from "../components/Sidebar";

// Dashboard Components
import Settings from "../source/Settings";
import PaymentGateway from "../source/PaymentGateway";
import Plans from "../source/Plans";

export default function AuthRouter() {
  document.body.classList.remove("d-flex-grid");
  document.body.classList.remove("bg-auth");
  return (
    <BrowserRouter>
      <Sidebar />
      <Switch>
        <Route path="/payment-methods" component={PaymentGateway} />
        <Route path="/settings" component={Settings} />
        <Route path="/" component={Plans} />
      </Switch>
    </BrowserRouter>
  );
}
