
const Cookies = require("js-cookie");

module.exports = {
  server: window.location.href.includes("localhost")
    ? "http://localhost:8000"
    : "https://api.crycoins.org",
  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("footprint"),
    },
  },
  RECAPTCHA_KEY: "6LeI5VoqAAAAAL-u0uAn_iLmSzsvMfMQgSN_3jDo",
  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("footprint");
        window.location.href = "/";
      }
    }
    return true;
  },
};
